import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {Link} from '@remix-run/react'
import {cx} from 'class-variance-authority'
import {forwardRef} from 'react'
import {ChevronRightIcon} from '../icons'

export function MarketingMenu() {
  return (
    <NavigationMenu.Root className="relative z-[1] flex w-full min-w-[32rem] flex-1 justify-start" delayDuration={0}>
      <NavigationMenu.List className="center flex list-none">
        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="group flex select-none items-center justify-between gap-2 rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none text-radix-mauve11 outline-none hover:bg-radix-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-radix-mauve7">
            Products{' '}
            <ChevronRightIcon
              className="relative top-[1px] rotate-90 text-radix-mauve10 transition-transform duration-200 ease-in group-data-[state=open]:-rotate-90"
              aria-hidden
            />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute left-0 top-0 w-full sm:w-auto">
            <ul className="m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[600px] sm:grid-cols-2">
              <ListItem href="/products/container-builds" title="Docker Build Service">
                Remote container build service, for CI and local development.
              </ListItem>
              <ListItem href="/products/github-actions" title="GitHub Actions Runners">
                Fully managed runners for GitHub Actions, deployed in AWS.
              </ListItem>
              <ListItem href="/products/cache" title="Depot Cache">
                Remote caching for Bazel, Gradle, Turborepo, sccache, and Pants.
              </ListItem>
              <ListItem href="/docs/container-builds/reference/api-overview" title="Build API">
                Fast, secure container builds, for untrusted customer code.
              </ListItem>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Trigger className="group flex select-none items-center justify-between gap-2 rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none text-radix-mauve11 outline-none hover:bg-radix-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-radix-mauve7">
            Resources{' '}
            <ChevronRightIcon
              className="relative top-[1px] rotate-90 text-radix-mauve10 transition-transform duration-200 ease-in group-data-[state=open]:-rotate-90"
              aria-hidden
            />
          </NavigationMenu.Trigger>
          <NavigationMenu.Content className="absolute left-0 top-0 w-full sm:w-auto">
            <ul className="m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[600px] sm:grid-cols-2">
              <ListItem title="Integrations" href="/integrations">
                Third-party integrations.
              </ListItem>
              <ListItem title="Help" href="/help">
                Help and support.
              </ListItem>
              <ListItem title="Company" href="/about">
                About us and our history.
              </ListItem>
              <ListItem title="Changelog" href="/changelog">
                Updates and new features.
              </ListItem>
            </ul>
            <ul className="m-0 grid list-none gap-x-[10px] border-t border-radix-mauve6 p-[22px] sm:w-[600px] sm:grid-cols-2">
              <ListItem title="depot.ai" href="https://depot.ai">
                Popular ML and AI models to embed in your build.
              </ListItem>
              <ListItem title="Dockerfile Explorer" href="/dockerfile-explorer">
                Visualize and understand the Dockerfile build graph.
              </ListItem>
              <ListItem title="GitHub Actions Price Calculator" href="/github-actions-price-calculator">
                A free and easy-to-use calculator for comparing GitHub Actions runners and prices.
              </ListItem>
            </ul>
          </NavigationMenu.Content>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Link
              className="block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none text-radix-mauve11 no-underline outline-none hover:bg-radix-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-radix-mauve7"
              to="/blog"
              prefetch="intent"
            >
              Blog
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Link
              className="block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none text-radix-mauve11 no-underline outline-none hover:bg-radix-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-radix-mauve7"
              to="/docs"
              prefetch="intent"
            >
              Docs
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Item>
          <NavigationMenu.Link asChild>
            <Link
              className="block select-none rounded-[4px] px-3 py-2 text-[15px] font-medium leading-none text-radix-mauve11 no-underline outline-none hover:bg-radix-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-radix-mauve7"
              to="/pricing"
              prefetch="intent"
            >
              Pricing
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Item>

        <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
          <div className="relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] bg-radix-mauve6" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className="absolute left-0 top-full flex justify-center">
        <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[6px] border border-radix-mauve6 bg-black transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
      </div>
    </NavigationMenu.Root>
  )
}

// eslint-disable-next-line react/display-name
const ListItem = forwardRef<HTMLAnchorElement, React.JSX.IntrinsicElements['a']>(
  ({className, children, title, ...props}, forwardedRef) => (
    <li>
      <NavigationMenu.Link asChild>
        <a
          className={cx(
            'block select-none rounded-[6px] p-3 text-[15px] leading-none no-underline outline-none transition-colors hover:bg-radix-mauve2 focus:shadow-[0_0_0_2px] focus:shadow-radix-mauve7',
            className,
          )}
          {...props}
          ref={forwardedRef}
        >
          <div className="mb-2 font-medium text-radix-mauve12">
            {title}{' '}
            {title === 'Changelog' ? (
              <div className="inline-block h-2 w-2 rounded-full border border-radix-violet10 bg-radix-violet8">
                <span className="sr-only"></span>
              </div>
            ) : null}
          </div>
          <p className="text-sm text-radix-mauve10">{children}</p>
        </a>
      </NavigationMenu.Link>
    </li>
  ),
)
